import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import CopyRight from "./../CopyRight";
//import { useTranslation } from "react-i18next";
const Footer = () => {
	//const { t } = useTranslation();

	return (
		<footer>
			<Container fluid className="footer">
				<div className="footer-link" aria-label="footerMenu">
					<ul>
						<li>
							<a href="/public/Lysol-Laundry-Sanitizer-T&C.pdf" target="new">
								Terms and Conditions
							</a>
						</li>
						<li>|</li>

						<li>
							<a href="https://www.lysol.com/privacy-policy#:~:text=We%20may%20use%20your%20information,information%2C%20for%20the%20following%20purposes%3A&text=To%20provide%20our%20Services%20to,for%20other%20customer%20service%20purposes" target="new">
								Privacy Policy
							</a>
						</li>
					</ul>
				</div>
				<CopyRight />
			</Container>
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
