import React, { Fragment } from "react";
import { logo } from "../data/index";
import { Link } from "react-router-dom";
const Logo = () => {
	return (
		<Fragment>
			<Link id="href-logo" to="/" className="navbar-brand">
				<img alt="LysolLaundry logo" src={logo}></img>
			</Link>
		</Fragment>
	);
};
export default Logo;
