import React, { useState, useEffect } from "react";
import { Row, FormGroup } from "react-bootstrap";
// eslint-disable-next-line
import { signup, login, getLockupAction, preRegistrationAction } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
//import Calendar from "react-calendar";
import Required from "../../components/Required";
import Captcha from "../../components/CaptchaClick";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import InputMask from "react-input-mask";
import { propTypes } from "react-bootstrap/esm/Image";
var validator = require("validator");
const Register = ({ signup, setLoading, login }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [place, setPlace] = useState({});
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [companyList, setCompanyList] = useState([]);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			errorArray[name] = t("");
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
			errorArray[name] = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		setError(errorArray);
		setData(d);
	};
	const errorMessages = {
		firstName: { reqMsg: "Please enter your first name.", invalidMsg: "First Name must be  maximum 128 characters long and must contain alphabets and special characters only." },
		lastName: { reqMsg: "Please enter your last name.", invalidMsg: "Last Name must be 128 characters long and must contain alphabets and special characters only." },
		email: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		confirmEmail: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		additionalInfo: { visitorId: "", requestId: "", agree_to_terms: false },
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", confirmEmail: "", lastName: "", agree_to_terms: "" });
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = t("Should be contain number");
				if (name === "password" || name === "passwordConfirm") {
					errorMessage = errorMessages[name].invalidMsg;
				}
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = t("Should be contain alphabets");
				if (name === "password" || name === "passwordConfirm") {
					errorMessage = errorMessages[name].invalidMsg;
				}
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = t("Should have atleast one special letter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}

			// if (name === "passwordConfirm") {
			// 	if (data.password !== value) {
			// 		setError({
			// 			...error,
			// 			passwordConfirm: t("Password and confirm password should be same"),
			// 		});
			// 	}
			// }
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);
	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				if (!isValidDate(value)) {
					errorMessage = "Enter a valid date in MM/DD/YYYY format.";
				} else {
					errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t("Invalid")} ${t("Birth Date")}`;
				}
			} else if (name === "birth") {
				errorMessage = "";
			}
			if (name === "birth" && isValidDate(value)) {
				let dt = new Date("2006-08-31");
				if (dt < new Date(data.birth)) {
					errorMessage = "Age must be 16 years or more on 31 Aug 2022, to register with this offer.";
				}
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email.");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = t("Should be contain number");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = t("Should be contain alphabets");
					if (name === "password" || name === "passwordConfirm") {
						errorMessage = errorMessages[name].invalidMsg;
					}
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
						if (name === "password" || name === "passwordConfirm") {
							errorMessage = errorMessages[name].invalidMsg;
						}
					}
				}
			}
			errorArray[name] = errorMessage;

			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("Email and confirm email should be same.");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = t("Please select captcha.");
		}
		if (!data.additionalInfo.agree_to_terms) {
			errorArray.agree_to_terms = t("Please review the above and indicate your agreement if you wish to proceed.");
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		delete newData.passwordConfirm;
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					console.log("PreRegistration");
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
						}
					} else {
						toast.error(t("Something went wrong"));
						setLoading(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid)
						.then((resp) => {
							console.log("upload page log");
							toast.success("Thank you! You have been registered for the promotion.");
							history.push("/upload-receipt");
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						})
						.catch((err) => {
							console.log("upload page error");
							toast.success(signupResp);
							history.push(`/register${window.location.search}`);
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
						});
				}
			} catch (error) {
				console.log("errror", error);
				setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("Something went wrong"));
				}
			}
		}
	};

	const handleDate = (value) => {
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let err = { ...error };
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
				err.address = "";
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
				err.state = "";
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
				err.city = "";
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
				err.suite = "";
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
				err.zip = "";
			}
			if (curStateData.hasOwnProperty("latitude")) {
				curStateData.latitude = place.latitude;
			}
			if (curStateData.hasOwnProperty("longitude")) {
				curStateData.longitude = place.longitude;
			}
			setData(curStateData);
			setError(err);
		}
		// eslint-disable-next-line
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	useEffect(() => {
		loadCompanyList();
	}, []);
	const loadCompanyList = () => {
		let requestData = { lookups: ["COMPANY_LIST"] };
		setLoading(true);
		getLockupAction(requestData)
			.then((response) => {
				if (response.httpCode === 200 && response.data) {
					if (response.data.content) {
						let comListInd = response.data.content.findIndex((v) => v.name === "COMPANY_LIST");
						let comList = [];
						if (comListInd >= 0) {
							comList = response.data.content[comListInd].data;
							let dt = response.data.content[comListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
							setCompanyList(dt);
						}
					}
				}
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
			});
	};
	const isValidDate = (date) => {
		// eslint-disable-next-line
		const matches = /^(\d{1,2})[/\/](\d{1,2})[/\/](\d{4})$/.exec(date);
		if (matches === null) {
			return false;
		}
		const [_, m, d, y] = matches;
		const composedDate = new Date(+y, +m - 1, +d);
		return composedDate.getDate() === +d && composedDate.getMonth() === +m - 1 && composedDate.getFullYear() === +y;
	};
	const formatChars = {
		"1": "[12]",
		"0": "[0123456789]",
	};

	const tooltip = <Tooltip id="tooltip">Please type in your mailing address and select the proper address from the dropdown.</Tooltip>;
	const ssnTooltip = <Tooltip id="tooltip">This is required for 1099 filings in the USA if you earn $600 or more in a calendar year.</Tooltip>;
	return (
		<div className="register-page">
			<div className="col-sm-10">
				<form role="form" aria-label="Registration">
					<h1 className="main-heading">{t("Register Now")}</h1>
					<Row>
						{/*[START:FIRSTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-firstname">
							<label htmlFor="first-name" className="col-form-label">
								<Trans>lang_key_fname</Trans>
								{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-firstName"
									name="firstName"
									onChange={handleChange}
									className="form-control"
									id="first-name"
									aria-label="Your First Name"
									// placeholder={t("lang_key_fname")}
								/>
								<span className="input-error">{error.firstName}</span>
							</div>
						</div>
						{/*[END:FIRSTNAME]*/}

						{/*[START:LASTNAME]*/}
						<div className="form-group col-sm-6 theme-signup-lastname">
							<label htmlFor="last-name" className=" col-form-label">
								<Trans>lang_key_lname</Trans>
								{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input
									type="text"
									data-test="register-lasttName"
									name="lastName"
									onChange={handleChange}
									className="form-control"
									id="last-name"
									aria-label="Your Last Name"
									// placeholder={t("lang_key_lname")}
								/>
								<span className="input-error">{error.lastName}</span>
							</div>
						</div>
						{/*[END:LASTNAME]*/}
					</Row>
					<Row>
						{/*[START:EMAIL]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email" className="col-form-label">
								<Trans>lang_key_email</Trans>
								{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" data-test="register-email" name="email" onChange={handleChange} className="form-control show-placegholder" id="email" placeholder="" aria-label="Your Email" />
								<span className="input-error">{error.email}</span>
							</div>
						</div>
						{/*[END:EMAIL]*/}
						{/*[START:EMAIL CONFIRM]*/}
						<div className="form-group col-sm-6 theme-signup-email">
							<label htmlFor="email-confirm" className="col-form-label">
								<Trans>lang_key_email_confirm</Trans>
								{validation["confirmEmail"] && validation["confirmEmail"].mandatory ? <Required /> : ""}
							</label>
							<div className="">
								<input type="email" data-test="register-email" name="confirmEmail" onChange={handleChange} className="form-control show-placegholder" id="email-confirm" placeholder="" aria-label="Confirm Your Email" />
								<span className="input-error">{error.confirmEmail}</span>
							</div>
						</div>
						{/*[END:EMAIL CONFIRM]*/}
					</Row>

					<Row>
						<div className="mt-3 col-12">
							<div className="btm-1 d-flex v-align-baseline form-check">
								<input type="checkbox" id="agree_to_terms" name="agree_to_terms" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_terms")} />{" "}
								<label htmlFor="agree_to_terms" className="form-check-label">
									{" "}
									I agree to the{" "}
									<a href="/public/Lysol-Laundry-Sanitizer-T&C.pdf" target="new" className="alink">
										Terms & Conditions
									</a>
									.
									<Required />
									<br />
									<span className="input-error">{error.agree_to_terms}</span>
								</label>
							</div>
						</div>
					</Row>

					{/*[START:GOOGLECAPTCHA]*/}
					{captchaEnable ? (
						<div className="form-group row mt-5">
							<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
								Google captcha
							</label>
							<div className="col-sm-12">
								<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
								<p className="input-error" style={{ marginTop: "-12px" }}>
									{" "}
									{error.captcha}
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/*[END:GOOGLECAPTCHA]*/}
					<div className="text-center">
						<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary">
							<Trans>REGISTER</Trans>
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default connect(null, { signup, login })(IsLoadingHOC(Register, "Wait ....."));
