import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button, Nav, Section, } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{
			id: 0, clicked: false, name: "How long do I have to submit my receipt?",
			content: "<p>All products must be purchased between 09/16/2022 and 10/16/2022.</p>"
		},
		{
			id: 1, clicked: false, name: "What do I do if I am having trouble participating in the program?",
			content: "You can submit any questions you may have about the promotion to our <a href='/support' class='alink'>support box</a>."
		},
		{
			id: 2, clicked: false, name: "What are the participating products?",
			content: "<ul><li>LYSOL® Antibacterial Laundry Refresher 6/22 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Crisp Linen 3/138 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Crisp Linen 4/37 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Crisp Linen 4/92 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Free & Clear 4/92 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Lavender 3/138 oz.</li>" +
				"<li>LYSOL® Laundry Detergent Lavender 4/92 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Crisp Linen 4/90 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Crisp Linen 6/41 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Free & Clear 4/90 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Free & Clear 6/41 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Fresh Blossoms 6/41 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Sport 4/90 oz.</li>" +
				"<li>LYSOL® Laundry Sanitizer Sport 6/41 oz.</li>" +
				"<li>LYSOL® Washing Machine Cleaner 4/36 oz.</li>" +
				"<li>LYSOL® Washing Machine Cleaner 6/36 oz.</li>" +
				"</ul>"
		},
		{
			id: 3, clicked: false, name: "How many times can I submit the same valid receipt? ",
			content: "You can only submit a valid receipt once, receipts submitted more than once will be rejected."
		},
		{
			id: 4, clicked: false, name: "How many rewards can I get?",
			content: "There is a limit of one reward per email address."
		},
		{
			id: 5, clicked: false, name: "What do I do if I have additional questions?",
			content: "Review the <a href='/public/Lysol-Laundry-Sanitizer-T&C.pdf' target='new' class='alink'>Terms and Conditions</a> for the offer for additional details. If the <a href='/public/Lysol-Laundry-Sanitizer-T&C.pdf' target='new' class='alink'>Terms and Conditions</a> have not answered your question, please email us via the <a href='/support' class='alink'>support box</a>."
		},
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<Container className="justify-content-md-center section-content" id="faq-content">
			<section role="contentinfo" aria-label="FAQ">
				<Row className="justify-content-md-center section-content">
					<Col md={12}>
						<h1 className="section-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h1>
					</Col>
					<Col md={12} className="text-right">
						<Button onClick={bulkActionHandler} variant="link" className="remove-underline">
							{!faqStatus ? "Open All" : "Close All"}
						</Button>
					</Col>
					<Col md={12} className="faq-page p-0">
						<Accordion>
							{data.map((v, i) => {
								return (
									<Card style={{ marginBottom: "16px" }}>
										<Card.Header>
											<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" className="p-0" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
												<Row style={{ cursor: "pointer" }}>
													<Col className="col-11">{v.name}</Col>
													<Col className="col-1 text-right pl-0 faq-line-icon">{v.clicked ? "-" : "+"}</Col>
												</Row>
											</Accordion.Toggle>
										</Card.Header>
										{v.clicked ? (
											<Accordion>
												<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
											</Accordion>
										) : (
											<Accordion.Collapse>
												<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
											</Accordion.Collapse>
										)}
									</Card>
								);
							})}
						</Accordion>
					</Col>
				</Row>
			</section>
		</Container>
	);
};

export default FrequentlyAskedQuestions;
