import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
class ParticipateInstructions extends React.Component {
	render () {
		return (
			<div id="participate-content" >
				<Row className="justify-content-md-center section-content" aria-label="content">
					<Col md={9}>
						<h1 className="section-heading text-center">How To Participate</h1>
					</Col>
					<Col md={9} className="text-center">

						<h2 className="htp-heading">
							BUY
						</h2>
						<p className="htp-content">Purchase one Lysol® Laundry Sanitizer product from Walmart, Target or Kroger between 09/16/2022 and 10/16/2022 and register.</p>
						<h2 className="htp-heading">
							TAKE A PICTURE
						</h2>
						<p className="htp-content">Snap a photo of your receipt showing your qualifying purchase. Don’t forget that the photo of your receipt must display the date and time of the transaction and the qualifying product purchased. If the receipt is too long, please fold the receipt so that the date and time and qualifying purchase are included in the picture. </p>
						<h2 className="htp-heading">
							UPLOAD
						</h2>
						<p className="htp-content">
							To upload your photo of your receipt, click the “SUBMIT” button and follow the instructions. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to one to two business days to process and will be subject to verification before an approval will be received, so please be patient.  All eligible receipts MUST be uploaded by 10/16/2022.
						</p>
						<h2 className="htp-heading">
							WRITE A REVIEW
						</h2>
						<p className="htp-content">
							Once your receipt is uploaded,  you will be redirected to choose the review site from where you purchased from - Walmart.com, Target.com or Kroger.com. Please leave a review in order to receive your reward.
						</p>
						<h2 className="htp-heading">
							GET REWARDED
						</h2>
						<p className="htp-content">
							You will receive your $5 digital prepaid card via email to the retailer you purchased from. Don’t forget to check your junk, promotions or spam folder if it’s not in your inbox.
						</p>
						<div className="text-center">
							<a href="/register" className="btn btn-primary" ><Trans>lang_key_joinNow</Trans></a>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ParticipateInstructions;
