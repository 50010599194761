import React from "react";
import { Container } from "react-bootstrap";
//import {footerCopyRight} from "../data";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
	const { t } = useTranslation();
	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="copy-text">
					<div>{t("copyrightText")}</div>
					<p className="tierlogic">
						{/* {footerCopyRight.poweredText} */}
						{t("Powered By ")}
						<a href="https://www.3tierlogic.com/" target="_blank">
							<img alt="3TL-logo" src="/public/images/3tl-logo-footer.png" />
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default CopyRight;
